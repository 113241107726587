import React from "react";
import "./Appnavatar.css";

function Aboutus() {

    return(
        <div class="aboutus">
        <div class="container text-center">
        <div class="row">
          <div class="col"><br/>
            <h3>Privacy Policy</h3><br/>
      <h6>Navatar respects the privacy of all its Customers and end users who are using our website.

The access and permission will be used only for the function of the App. Data will not be seen or given to any third party entity.

</h6>
          </div>
          
        </div>
        </div>
        
       
      </div>
    )
}

export default Aboutus;
