import React from "react";

function App() {
    
const form = "Doctor Profile";
    return(
        <h1>{form}</h1>
    )
}

export default App;